import React, { useState, useEffect } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import AbsenceCointainer from '../component/AbsenceContainer';
import PageNotFound from '../component/PageNotFound';
import Headers from '../component/header/Header';
import MobileHeader from '../component/header/MobileHeader';
import { useDispatch, useSelector } from 'react-redux';
import * as constant from '../constants/Constant';
import { signin } from '../slices/authenticationSlice';
import {fetchDsGroupIds,fetchSecurityRefDsGroupIds,setFilterData} from '../slices/headerSlice';

const startTime = performance.now();

const RootRouter = ({reporter}) => {
    const navigate = useNavigate(),
        dispatch = useDispatch(),
        authenticationData = useSelector((state) => state.authenticationData),
        header = useSelector((state) => state.header),
        summary = useSelector((state) => state.summary),
        [loadTime, setLoadTime] = useState(null),
        [accessToDashboard,setAccessToDashboard] = useState(false),
        isMobile = window.matchMedia("(max-width: 768px)").matches;
        //[isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 768);

    const apiSignIn = (code) => {
        dispatch(signin({ authorizationCode: code }))
    }

    const redirectToIdms = () => {
        let authorizationUrl = `${constant.idms_auth}${constant.client_id}&redirect_uri=${constant.redirect_uri}&response_type=code&state=state&scope=${constant.scope}`;
        window.location.replace(authorizationUrl);
    }

    async function getAuthenticationDetails() {
        const search = window.location.search,
            params = new URLSearchParams(search),
            code = params.get("code");
            //code = 'c7999b0be75254068becb3651cae67409';

        console.log("-------code------", code)

        //await redirectToIdms();
        if (code) {
            await apiSignIn(code);
        } else {
            await redirectToIdms();
        }
    }

    useEffect(() => {
        getAuthenticationDetails()
    }, [])
    useEffect(() => {
        if (authenticationData.isSigninSuccess) {
            dispatch(fetchDsGroupIds());
        }
    }, [authenticationData.isSigninSuccess])
    useEffect(() => {
        if (Object.keys(header.cookieData).length > 0) {
            const dsGrpIds = header.cookieData.dsGroupIds.split(',').map(String);
            const isIncluded = dsGrpIds.includes('11464540') || dsGrpIds.includes('11464436');
            if (isIncluded) {
                setAccessToDashboard(true);
                dispatch(fetchSecurityRefDsGroupIds());
            } else {
                alert("You don't have access to this dashboard");
            }
        }
    }, [header.cookieData])

    useEffect(() => {
        if (Object.keys(header.cookieData).length > 0) {
            const dsGrpIds = header.cookieData.dsGroupIds.split(',').map(String),
                list = header.securityRefIds,
                allDsGrpIds = list.map(item => item.Ds_Grp_Id),
                convertedProvidedList = dsGrpIds.map(Number),
                isMatch = convertedProvidedList.some(id => allDsGrpIds.includes(id));
                
            if (isMatch) {
                dispatch(setFilterData({ state: "corporateEmployee", value: true }));
            }
        }
    }, [header.securityRefIds])

    useEffect(() => {
        if (authenticationData.isSigninSuccess) {
            navigate("/")
        }
    }, [authenticationData.isSigninSuccess])

    useEffect(() => {
        const endTime = performance.now(),
            loadTimeInSeconds = (endTime - startTime) / 1000;

        if (!header.isApplyClicked) {
            if (authenticationData.isSigninSuccess && (header.cookieData.dsGroupIds) && (header.corporateTimeframeData?.length > 0) && (header.securityRefIds?.length > 0) && (header.timeframeData?.length) > 0) {

                setLoadTime(loadTimeInSeconds.toFixed(2));
            }
        }
    }, [authenticationData.isSigninSuccess, header.cookieData.dsGroupIds, header.corporateTimeframeData, header.securityRefIds, header.timeframeData]);

    useEffect(() => {
        let maskedDsId = "";
            
        if(maskedDsId && loadTime) {
            reporter.reportEvent({
                eventType: 'load',
                controlId: 'Personal Absence Report Initial Load',
                controlValue: `DSID:${maskedDsId}, loadtime:${loadTime}`
            });

            reporter.reportCustomKeys({
                userRole: 'market support',
                customkey1: maskedDsId,
                customkey2: 'R001',
                customkey3: `${loadTime} sec`
            });
        }
    }, [loadTime])

    /* to check the screen size when window resizes */
    // useEffect(() => {
    //     const handleResize = () => {
    //       setIsMobileScreen(window.innerWidth <= 768);
    //     };
    
    //     window.addEventListener('resize', handleResize);
    
    //     return () => {
    //       window.removeEventListener('resize', handleResize);
    //     };
    // }, []);
    
    return (
        <div>
            {
                authenticationData.isSigninSuccess && accessToDashboard ?
                    <Routes>
                        <Route exact path="/" element={
                            <div>
                                {isMobile ? <MobileHeader /> : <Headers />}
                                <AbsenceCointainer />
                            </div>} />
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                    :
                    <></>
            }
        </div>
    )
}
export default RootRouter;