import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BricksTooltip } from '@nexus/bricks-react';
import * as Util from '../../util/Util';
import NoDataDisplayContainer from '../common/nodata_display_container/NoDataDisplayContainer';
import './MobileCalender.scss';

const MobileCalender = () => {
    const calenderData = useSelector((state) => state.calender);
    const dayOrder = ["SATURDAY", "SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];
    const dayCapitalMapping = {
        'SATURDAY': "Saturday", 'SUNDAY': 'Sunday', 'MONDAY': 'Monday', 'TUESDAY': 'Tuesday', 'WEDNESDAY': 'Wednesday', 'THURSDAY': 'Thursday', 'FRIDAY': 'Friday'
    };

    let obj = {
        Emp_Name: "",
        Rptg_Dt: "",
        Child_Cd: "",
        SCHED_YEAR_WEEK_NR: 0,
        Day_Name: "",
        Week_Start_Day: "",
        Event_Name: "",
        Excusal_Status_Cd: "",
        Week_Start_Dt: "",
        Threshold_Val: 0,
        Point_Balance_Val: 0,
        Above_Threshold_Ind: "N",
        Exclude_Occurrence_Ind: "N",
        Tot_Impacted_Hours_Val: 0,
        Tot_Planned_Hours_Val: 0,
        Clocked_Time_Hrs: 0,
        Excused_Benefit_Time_Hrs: 0,
        Unexcused_Benefit_Time_Hrs: 0,
        Unapproved_Abs_Hrs: 0,
        Leave_Of_Absence_Hrs: 0,
        Excused_Leader_Hrs: 0
    }
    let [calendarGroupedData, setCalendarGroupedData] = useState(""),
        [showPopup, setShowPopup] = useState(false),
        [popupContent, setPopupContent] = useState(obj),
        [getType, setType] = useState(''),
        [groupWeekData, setGroupWeekData] = useState({}),
        popupRef = useRef(null);

    const handleBadgeClick = (content) => {
        setShowPopup(true);
        setPopupContent(content);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setPopupContent(null);
    };

    useEffect(() => {
        if (popupRef.current && showPopup) {
            popupRef.current.style.maxHeight = `${popupRef.current.scrollHeight}px`;
        }
    }, [showPopup]);



    const groupByTwoProperties = (arr, key1, key2) => {
        return arr.reduce((result, obj) => {
            const key1Value = obj[key1], key2Value = obj[key2];
            if (!result[key1Value]) result[key1Value] = {};
            if (!result[key1Value][key2Value]) result[key1Value][key2Value] = [];
            result[key1Value][key2Value].push(obj);
            return result;
        }, {});
    };

    const formatDateToString = (dateString) => {
        const [year, month, day] = dateString.split('-');
        const monthIndex = parseInt(month, 10);
        const dayNumber = parseInt(day, 10);
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };
        const dayWithSuffix = dayNumber + getOrdinalSuffix(dayNumber);
        const lastTwoDigitsOfYear = year.slice(-2);
        return `${dayWithSuffix} ${monthNames[monthIndex - 1]} ${lastTwoDigitsOfYear}`;
    };

    const sortDataByDay = (data) => {
        const sortedData = {};
        Object.keys(data).forEach(key => {
            const days = Object.keys(data[key]);
            const sortedDays = days.sort((a, b) => {
                return dayOrder.indexOf(a.toUpperCase()) - dayOrder.indexOf(b.toUpperCase());
            });
            sortedData[key] = {};
            sortedDays.forEach(day => {
                sortedData[key][day] = data[key][day];
            });
        });
        return sortedData;
    };

    const getUnexcusedDetails = (employeeDataObj) => {
        let unexcusedString = '';

        //if (employeeDataObj.Excusal_Status_Cd == 'Unexcused') {
        if (employeeDataObj.Unapproved_Abs_Hrs > 0) {
            unexcusedString = Util.getFormattedTime(employeeDataObj.Unapproved_Abs_Hrs, ' ') + ' - ' + 'Unexcused' + ' - Leave'
        } else if (employeeDataObj.Unexcused_Benefit_Time_Hrs > 0) {
            unexcusedString = Util.getFormattedTime(employeeDataObj.Unexcused_Benefit_Time_Hrs, ' ') + ' - ' + 'Unexcused' + ' - Time Away'
        }

        return unexcusedString
        //}
    }
    const getexcusedDetails = (employeeDataObj) => {
        let excusedString = '';

        //if (employeeDataObj.Excusal_Status_Cd == 'Excused') {
        if (employeeDataObj.Leave_Of_Absence_Hrs > 0) {
            excusedString = Util.getFormattedTime(employeeDataObj.Leave_Of_Absence_Hrs, ' ') + ' - ' + 'Excused' + ' - Leave'
        } else if (employeeDataObj.Excused_Benefit_Time_Hrs > 0) {
            excusedString = Util.getFormattedTime(employeeDataObj.Excused_Benefit_Time_Hrs, ' ') + ' - ' + 'Excused' + ' - Time Away'
        } else if (employeeDataObj.Excused_Leader_Hrs > 0) {
            excusedString = Util.getFormattedTime(employeeDataObj.Excused_Leader_Hrs, ' ') + ' - ' + 'Excused' + ' - By Leader'
        }

        return excusedString
        //}
    }
    const getExcusedUnexcusedDetails = (employeeDataObj) => {
        let divArr = [],
            excusalDetails = getexcusedDetails(employeeDataObj),
            unExcusalDetails = getUnexcusedDetails(employeeDataObj);

        if (unExcusalDetails || excusalDetails) {
            divArr.push(<div className='separator' />);

            if (unExcusalDetails) {
                divArr.push(<div class="event-tooltip-clocktime">
                    <span className="icon alert"></span>
                    <span className="event-padding">{unExcusalDetails}</span>
                </div>)
            }
            if (excusalDetails) {
                divArr.push(<div class="event-tooltip-clocktime">
                    <span className="icon alert"></span>
                    <span className="event-padding">{excusalDetails}</span>
                </div>)
            }
        }

        return <>{divArr}</>
    }

    const getSeparatorClass = (employeeDataObj) => {
        let cls = '';
        let excusalDetails = getexcusedDetails(employeeDataObj),
            unExcusalDetails = getUnexcusedDetails(employeeDataObj);
        if(excusalDetails || unExcusalDetails){
            cls = 'separator-with-border';
        }else{
            cls = 'separator-without-border'
        }
        return cls;
    }
    const getPopContent = () => {

        let type = popupContent.Excusal_Status_Cd.replace(' ', '');
        let excusalDetails = getExcusedUnexcusedDetails(popupContent),
            shiftTime = Util.getFormattedTime(popupContent.Tot_Planned_Hours_Val, ' '),
            shiftStartTime = Util.convertToAmPm(popupContent.Shift_Start_Tm_Aggr),
            shiftEndTime = Util.convertToAmPm(popupContent.Shift_End_Tm_Aggr),
            separator = getSeparatorClass(popupContent);


        return (
            <div className={`popup-container ${popupContent ? 'show' : ''}`} ref={popupRef}>
                <div className={`popup-header ${type.replace(' ', '')}`}>
                    <div className="popup-title">
                        <div className={`event-name-${type.replace(' ', '')}`}>
                            <span>{(popupContent.Event_Name != 'No Event') ? popupContent.Event_Name : '-'}</span>
                        </div>
                        <div>
                            <span className={`status-badge-${type.replace(' ', '')}`}>{popupContent.Excusal_Status_Cd}</span>
                            <button className="close-btn" onClick={handleClosePopup}>
                                <span className="close-icon">✕</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="popup-content">
                    <div className={`popup-row ${type.replace(' ', '')}`}>
                        <span class="event-tooltip-calendar-icon"></span>
                        <span className="text">
                            {dayCapitalMapping[popupContent.Day_Name]} - {formatDateToString(popupContent.Rptg_Dt)}
                        </span>
                    </div>
                    <div className={`popup-row ${type.replace(' ', '')}`}>
                        <span class="event-tooltip-shift-icon"></span>
                        <span class="text">{shiftStartTime + ' - ' + shiftEndTime + ' (' + shiftTime + ')'}</span>
                    </div>
                    {(type != 'NoEvent') ? <div className={`popup-row ${separator}`}>
                        <span className="event-tooltip-clock-icon"></span>
                        <span className="text">
                            {Util.getFormattedTime(
                                popupContent.Event_Name === 'No Event'
                                    ? popupContent.Clocked_Time_Hrs
                                    : popupContent.Tot_Impacted_Hours_Val,
                                ''
                            ) + ' Impact'}
                        </span>
                    </div> : null}
                    {(type != 'NoEvent') ? <div className="popup-row">

                        <span className="text">{excusalDetails}</span>
                    </div> : null}
                </div>
            </div>
        );
    }

    useEffect(() => {
        let groupedData = groupByTwoProperties(calenderData.calenderDetails, 'Child_Cd', 'Day_Name');
        let sortedData = sortDataByDay(groupedData);
        let groupByWeek = Util.groupBy(calenderData.calenderDetails, "Child_Cd");
        setGroupWeekData(groupByWeek);
        setCalendarGroupedData(sortedData);
    }, [calenderData.calenderDetails]);


    return (
        <div className="mobile-calender-container">
            <div className="calender-header">Calendar View</div>
            <div className="cal">
                {Object.keys(calendarGroupedData).map((weekKey) => (
                    <div key={weekKey} className="calender-section">
                        <div className="header-week">
                            <div className='week-header'>{weekKey}</div>
                            <span className="date-range">{Util.getDateRange(groupWeekData[weekKey][0].Week_Start_Dt, groupWeekData[weekKey][0].Week_End_Dt)}</span>
                        </div>
                        <div className="calender-section-container">

                            <div className="week" role="list">
                                <div class="attendance-record">
                                    {Object.keys(calendarGroupedData[weekKey]).map((dayKey) => (
                                        <div key={dayKey} className="day-container">
                                            <div class="header">
                                                <span class="day">{dayCapitalMapping[dayKey]}</span>
                                                <span class="date">{formatDateToString(calendarGroupedData[weekKey][dayKey][0]["Rptg_Dt"])}</span>
                                            </div>
                                            {calendarGroupedData[weekKey][dayKey].map((day, index) => (
                                                <div key={index} className="day-content" onClick={() => handleBadgeClick(day)}>

                                                    <div class="event" >
                                                        <span className={`status-text ${day.Excusal_Status_Cd.replace(/ /g, '')}`}>
                                                            {(day.Event_Name !== 'No Event') ? day.Event_Name : '-'}
                                                        </span>
                                                        <span className={`badge ${day.Excusal_Status_Cd.replace(/ /g, '')}`}>
                                                            {day.Excusal_Status_Cd}
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {popupContent && showPopup &&
                getPopContent()
            }
        </div>
    );
};

export default MobileCalender;
