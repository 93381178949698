import React, { useState,useEffect } from 'react';
import { BricksRadioButtonGroup, BricksRadioButton, BricksTextField } from '@nexus/bricks-react';
import { useSelector, useDispatch } from 'react-redux';


const ListView = ({itemClick}) => {

  const header = useSelector((state) => state.header);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [data,setData] = useState([]);



  useEffect(() => {
    setData(header.corporateStoreList);
  }, [header.corporateStoreList]);
  // Handle radio button selection
  const handleChange = (value) => {
    setSelectedOrg(value);
    let storeId = data.find(store=>store.Store_Name == value)
    itemClick(storeId);
  };

  // Filter 
  const filteredData = data.filter((item) =>
    item.Store_Name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    item.Store_Cd?.toLowerCase().includes(searchTerm.toLowerCase())
);

  return (
    <div class="store-container">
      <label className="store-label">Store</label>
      <div className='store-content'>
        <div class="search-box">
          <BricksTextField label="Standard" className='custom-text-field' onBricksTextFieldChange={e => setSearchTerm(e.detail.value)}  showClearButton={true} showSearchIcon={true} placeHolderText={'Search Store'}></BricksTextField>
        </div>
        <div class="search-result">
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div className='custom-radio'>
                <BricksRadioButton
                  key={item.Store_Cd}
                  label={item.Store_Name}
                  value={item.Store_Name}
                  name="store"
                  checked={selectedOrg == item.Store_Name}
                  onBricksRadioButtonChange={() => handleChange(item.Store_Name)}
                />
              </div>
            ))
          ) : (
            <p>No stores found</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ListView;
